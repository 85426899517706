body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*
table.table tbody tr td,
table.table thead tr th {
border:1px dotted black;
border-collapse:collapse !important;
padding:0;
margin:0;
}
*/

div.datasources{
    background-color: white;
    padding: 1rem;
}
/* Metadata table */

table.meta-table,
table.data-sources-table {
	font-size:12px;
	width:99%;
	margin-bottom:.5em;border-collapse: collapse;

  }
  table.meta-table tbody tr,
  table.meta-table thead tr,
  table.data-sources-table tbody tr,
  table.data-sources-table thead tr {
    border-collapse:collapse;
  }
  table.meta-table tbody tr td,
  table.meta-table thead tr th,
  table.data-sources-table tbody tr td,
  table.data-sources-table thead tr th{
	border:1px dotted black;
	border-collapse:collapse !important;
	padding: .25em 1em;
  }
  
  table.meta-table tbody tr:nth-child(even) ,
  table.data-sources-table tbody tr:nth-child(even) {
  background-color:#eee;
  }
  table.meta-table tr th,
  table.data-sources-table tr th {
	text-align:center;
  }
  
  
.meta-table,
.data-sources-table { 
    table-layout: fixed;
}
.meta-table th:nth-child(2){
    width:40%;
}
.meta-table th:nth-child(3){
    width:20%;
}
.meta-table th:nth-child(4){
    width:20%;
}
.meta-table th:nth-child(5){
    width:9%;
}
.meta-table th:nth-child(7){
    width:20%;
}


.data-sources-table th:nth-child(2){
  width:40%;
}
.data-sources-table th:nth-child(3){
    width:10%;
}
.data-sources-table th:nth-child(5){
    width:20%;
}

