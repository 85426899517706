/*
.CalciteApp {
  text-align: center;
}

.CalciteApp-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .CalciteApp-logo {
    animation: CalciteApp-logo-spin infinite 20s linear;
  }
}
*/
/*
.CalciteApp-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.CalciteApp-link {
  color: #61dafb;
}

@keyframes CalciteApp-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
div.button, div.text-container 
.button div.text-container {
	line-height: 1.1rem;
}
.mapDiv {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

.no-margin-top {
  margin-top:0;
}
.no-margin-bottom {
  margin-bottom:0;
}
.small-margin-bottom {
  margin-bottom:.5rem;
}

table.table {
  width: 100%;;
}
.ahp-tool-th .label {
  padding:0;
}
/*
.ahp-tool-th span{
	rotate: 225deg;
	writing-mode: vertical-rl;
} 
*/
.ahp-tool-th span{
	writing-mode: vertical-lr;
  rotate: 180deg;
} 

.ahp-tool-th.th-33 {
  width:33%;
}

.presentation {
  display:flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding:0 1rem;
}
.presentation .presentation-content{
  flex:1;
  max-width:1200px;

  
}